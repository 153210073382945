import { Card, CardContent } from '@repo/ui/components/Card.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { H2 } from '@repo/ui/components/Typography.js'
import { CategoryBadge } from '~/components/CategoryBadge'
import { TextLink } from '~/components/TextLink'
import { UserAvatar } from '~/components/UserAvatar'
import { VendorIcon } from '~/components/VendorIcon'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import type { NewProduct } from './route.config'

export const NewProducts = ({ products }: { products: NewProduct[] }) => {
  const org = useAuthenticatedOrg()

  if (!products.length) return null

  return (
    <div className="space-y-4">
      <H2>New at {org.name}</H2>
      <div className="grid grid-cols-4 gap-4">
        {products.map((product) => (
          <Card key={product.productId}>
            <CardContent className="flex flex-col p-0">
              <div className="flex items-center gap-3 px-4 py-3">
                <VendorIcon src={product.iconUrl} className="size-8" />
                <div>
                  <TextLink
                    to={`/apps/${product.vendorId}/products/${product.productId}`}
                    className="font-semibold leading-none"
                  >
                    {product.name}
                  </TextLink>
                  {product.category ? (
                    <div>
                      <CategoryBadge size="xs" {...product.category} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center justify-between bg-base-50 px-4 py-3 text-xs dark:bg-base-800">
                <TextLink
                  to={`/team/${product.employee.id}`}
                  className="group flex items-center gap-2 text-muted-foreground "
                >
                  <UserAvatar
                    src={product.employee.avatarUrl}
                    className="size-4"
                  />
                  <span>{product.employee.displayName}</span>
                </TextLink>

                <div className="flex items-center gap-1 text-muted-foreground text-xs opacity-70">
                  <Icon name="arrow-click" className="size-3" />
                  {product.firstUsed}
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}
