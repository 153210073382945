import { H2 } from '@repo/ui/components/Typography.js'
import { LinkButton } from '~/components/LinkButton'
import { Illustrations } from '~/components/illustrations'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'

export const DashboardEmptyState = () => {
  const { PUBLIC } = useClientEnvironmentVariables()
  return (
    <div className="flex min-h-96 w-full flex-col items-center justify-center gap-6 rounded-xl border border-border bg-card p-12">
      <div className="w-40">{Illustrations.celebration()}</div>
      <div className="space-y-4 text-center">
        <div className="space-y-2">
          <H2>Welcome! We're thrilled you're here.</H2>
          <p className="text-md text-muted-foreground">
            Deploying Pulse across your org takes just a couple minutes.
          </p>
        </div>
        <LinkButton to={PUBLIC.DOCS_URL} icon="checklist" target="_blank">
          Read the docs
        </LinkButton>
      </div>
    </div>
  )
}
