import type { IconName } from '@repo/ui/components/Icon.js'
import { Statistic } from '~/components/Statistic'

type StatisticType = {
  label: string
  icon: IconName
  value: number | string
}

export const SessionStatistics = ({
  statistics,
}: { statistics: StatisticType[] }) => {
  return (
    <div className="grid auto-cols-auto grid-flow-col gap-4">
      {statistics.map((stat) => (
        <Statistic
          key={stat.label}
          label={stat.label}
          icon={stat.icon}
          value={stat.value}
        />
      ))}
    </div>
  )
}
