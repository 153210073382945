import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@repo/ui/components/Card.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { cn } from '@repo/ui/lib/utils'
import { useMemo } from 'react'
import { TextLink } from '~/components/TextLink'
import { VendorIcon } from '~/components/VendorIcon'
import { formatNumberCompact } from '~/utils/numbers'
import { LOOKBACK_PERIOD_DAYS } from './route.config'

type App = {
  id: string
  name: string
  iconUrl: string
  userChange: number
  currentPeriodUsers: number
}

export const UsageTrends = ({ apps }: { apps: App[] }) => {
  const { trendingUp, trendingDown } = useMemo(() => {
    return apps.reduce<{ trendingUp: App[]; trendingDown: App[] }>(
      (acc, cur) => {
        if (cur.userChange > 0) {
          acc.trendingUp.push(cur)
        } else {
          acc.trendingDown.push(cur)
        }
        return acc
      },
      {
        trendingUp: [],
        trendingDown: [],
      }
    )
  }, [apps])

  return (
    <Card>
      <CardHeader>
        <CardTitle>Usage trends</CardTitle>
        <CardDescription>
          Active users last {LOOKBACK_PERIOD_DAYS} days vs previous period
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <TrendingAppsList apps={trendingUp} direction="increase" />
          <TrendingAppsList apps={trendingDown} direction="decrease" />
        </div>
      </CardContent>
    </Card>
  )
}

const TrendingAppsList = ({
  apps,
  direction,
}: { apps: App[]; direction: 'increase' | 'decrease' }) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-1.5 border-border border-b-2 pb-1 font-semibold">
        <Icon
          name={direction === 'increase' ? 'trending-up' : 'trending-down'}
          className="size-3"
        />
        Trending {direction === 'increase' ? 'up' : 'down'}
      </div>
      {apps.length ? (
        <div className="divide-y divide-border">
          {apps.map((app) => (
            <div
              key={app.id}
              className="flex items-center justify-between gap-4 py-2"
            >
              <TextLink
                to={`/apps/${app.id}`}
                className="flex items-center gap-1"
              >
                <VendorIcon src={app.iconUrl} className="size-4" />
                <div className="font-medium">{app.name}</div>
              </TextLink>
              <div className="flex items-center gap-2 text-xs">
                <div className="flex items-center gap-1 text-muted-foreground">
                  <Icon name="users" className="size-3" />
                  <span>{app.currentPeriodUsers}</span>
                </div>
                <div
                  className={cn(
                    'flex w-11 items-center justify-center gap-1 rounded-sm px-1.5 py-0.5 font-bold',
                    direction === 'increase' &&
                      'bg-success-100 text-success-600 dark:bg-success-500/30 dark:text-success-200/80',
                    direction === 'decrease' &&
                      'bg-destructive-100 text-destructive-600 dark:bg-destructive-500/30 dark:text-destructive-200/80'
                  )}
                >
                  <Icon
                    name={
                      direction === 'increase' ? 'trending-up' : 'trending-down'
                    }
                    className="size-3"
                  />
                  {formatNumberCompact(Math.abs(app.userChange))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center rounded-md bg-secondary p-2 text-muted-foreground">
          Nothing for now. Over time, trends will appear here.
        </div>
      )}
    </div>
  )
}
