import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@repo/ui/components/Card.js'
import { HorizontalBarChart } from '~/components/HorizontalBarChart'
import { TextLink } from '~/components/TextLink'
import { VendorIcon } from '~/components/VendorIcon'
import { LOOKBACK_PERIOD_DAYS, type PopularApp } from './route.config'

export const PopularAppsChart = ({ apps }: { apps: PopularApp[] }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Popular apps</CardTitle>
        <CardDescription>
          Active users last {LOOKBACK_PERIOD_DAYS} days
        </CardDescription>
      </CardHeader>
      <CardContent>
        <HorizontalBarChart
          bars={apps.map((app) => ({
            ...app,
            label: app.name,
            value: app.maus,
          }))}
          renderLabel={(app) => (
            <TextLink
              to={`/apps/${app.id}`}
              className="flex h-full w-fit items-center justify-start gap-2 pl-2"
            >
              <VendorIcon src={app.iconUrl} className="size-3.5" />
              <span>{app.name}</span>
            </TextLink>
          )}
        />
      </CardContent>
    </Card>
  )
}
