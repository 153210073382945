import { Skeleton } from '@repo/ui/components/Skeleton.js'

export const DashboardSkeleton = () => {
  return (
    <div className="grid grid-cols-4 gap-4">
      {Array.from({ length: 4 }).map((_, i) => (
        <Skeleton key={i} className=" h-20 w-full" />
      ))}

      {Array.from({ length: 2 }).map((_, i) => (
        <Skeleton key={i} className="col-span-2 h-96 w-full" />
      ))}

      {Array.from({ length: 8 }).map((_, i) => (
        <Skeleton key={i} className=" h-20 w-full" />
      ))}
    </div>
  )
}
